.remirror-theme {
  background-color: white;
}
.remirror-theme .ProseMirror {
  /*min-height: var(--rmr-space-6);*/
  box-shadow: none !important;/*var(--rmr-color-border) 0px 0px 0px 0.1em;*/
  padding: 0;/*var(--rmr-space-3);
    border-radius: 0 !important;/*var(--rmr-radius-border);*/
  /*outline: none;*/
  padding-bottom: 12px;
  overflow-x: hidden;
  overflow-y: hidden !important;
}

.remirror-theme .ProseMirror:active,
.remirror-theme .ProseMirror:focus {
  box-shadow: none !important;/*var(--rmr-color-outline) 0px 0px 0px 0.2em;*/
}

.remirror-editor-wrapper {
  padding-top: 0;
}

.remirror-editor {
  font-size: 12pt !important;
  line-height: normal !important;
}

/* This fixes an issue where text color is ignored when text highlight is active */
.remirror-theme .ProseMirror mark {
  color: inherit;
  background-color: green;
}

.remirror-theme .ProseMirror p,
.remirror-theme .ProseMirror h1,
.remirror-theme .ProseMirror h2,
.remirror-theme .ProseMirror h3,
.remirror-theme .ProseMirror h4,
.remirror-theme .ProseMirror h4,
.remirror-theme .ProseMirror h5,
.remirror-theme .ProseMirror h6,
.remirror-theme .ProseMirror span {
  /*margin: 0;*/
  margin-bottom: var(--rmr-space-2);
}